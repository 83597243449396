import React from "react";
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout";
import Seo from "../components/seo";
import PageTitle from "../components/pageTitle";

import fish1 from "../images/fish1.svg"
import fish2 from "../images/fish2.svg"
import fish3 from "../images/fish3.svg"


const Fish = () => (
    <Layout>
        <Seo title="About" />
        <PageTitle>Fish</PageTitle>
        <div className="fish-area">
            <p>
                I've always wanted to learn 3D animation, so I decided to turn a doodle of mine into an SVG animation. The fish are originally 3D objects in Blender, I then used Freestyle SVG Exporter to create animated SVG's. It required some tweaking of the SVG code and filling in colors in Inkscape but finally I got something I was happy with.
            </p>

            <p>
                The original doodle:
            </p>
            <StaticImage
                src="../images/fish-doodle.jpg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="My fish doodle"
                className="mb-8"
            />
            <p>
                I then created a semi-3D fish for ease of animation:
            </p>
            <StaticImage
                src="../images/fish-3d.jpg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="My fish doodle"
                className="mb-8"
            />
            <p>
                And after animating the 3D fish I used Freestyle to export the outline as an SVG. I then had to open each layer in Inkscape and filled in the dark spots.
            </p>
            <p>
                This is the result:
            </p>
            <img alt="A fish" src={fish1} />
            <img alt="Another fish" src={fish2} />
            <img alt="A fish again" src={fish3} />
        </div>
    </Layout>
);

export default Fish;
